import Decimal from "decimal.js";
import React from "react";

import { RoundedCurrency } from "app/lib/credits";
import { printDateRange, toDayjs, useNow } from "lib/date";
import { CommitReference } from "app/pages/Contracts/components/CommitReference";
import { ContractLineItem } from "../../types";
import { DeprecatedTablePanel } from "components/deprecated/TablePanel";
import { renderDate } from "lib/time";
import { TierMetadata } from "types/generated-graphql/__types__";
import {
  groupLineItems,
  isUngrouped,
} from "components/deprecated/Invoice/lib/presentationGroupedLineItemUtils";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";
import { Quantity } from "components/Quantity";

interface Props {
  issuedAt: Date;
  exclusiveEndDate: Date | undefined;
  lineItems: ContractLineItem[];
  invoiceType:
    | "AdHocPlanInvoice"
    | "AdvanceInvoice"
    | "ArrearsInvoice"
    | "ContractScheduledInvoice"
    | "ContractProServiceInvoice"
    | "ContractUsageInvoice"
    | "AdhocContractUsageInvoice"
    | "ContractRefundInvoice"
    | "ContractPostpaidTrueupInvoice"
    | "CorrectionInvoice"
    | "SeatPurchaseInvoice"
    | "CreditPurchaseInvoice"
    | "ParentInvoice";
  showChargesWithZeroUsage: boolean;
  onRowClick?: (row: ContractLineItem) => void;
}

type LineItemGroupRow = {
  presentationGroupValues:
    | {
        name: string;
        value: string | null;
      }[]
    | null;
  type: "header";
};
type LineItemRow = {
  lineItem: ContractLineItem;
  type: "data";
};

export const DeprecatedContractLineItems: React.FC<Props> = (props) => {
  const now = useNow();
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  const formatTierText = (tierMetadata: TierMetadata) => {
    const { starting_at, size } = tierMetadata;
    const end = size ? Number(starting_at) + Number(size) : undefined;
    return ` (>${starting_at}${end ? ` - ${end}` : ``})`;
  };
  const filteredLineItems = props.lineItems
    .filter(
      // these line items are only shown in the totals
      (li) => li.__typename !== "ContractAppliedCommitLineItem",
    )
    .filter(
      (li) =>
        props.showChargesWithZeroUsage ||
        !("quantity" in li && new Decimal(li.quantity).isZero()),
    );

  const groupedLineItems = groupLineItems(filteredLineItems);

  const lineItemRows = (group: ContractLineItem[]) =>
    group.map((lineItem) => {
      const lineItemRow: LineItemRow = {
        lineItem: lineItem,
        type: "data",
      };
      return lineItemRow;
    });

  const hasGroupedItems = groupedLineItems.some(
    (group) => !isUngrouped(group[0]),
  );
  const rows: (LineItemRow | LineItemGroupRow)[] =
    groupedLineItems.length === 0
      ? []
      : !hasGroupedItems
        ? lineItemRows(groupedLineItems[0])
        : groupedLineItems.flatMap((group) => {
            const groupHeader: LineItemGroupRow = {
              type: "header",
              presentationGroupValues:
                group[0].__typename === "ContractUsageLineItem"
                  ? (group[0].presentation_group_values || []).length > 0
                    ? group[0].presentation_group_values
                    : null
                  : null,
            };
            return [groupHeader, ...lineItemRows(group)];
          });

  const getGroupHeader = (group: LineItemGroupRow) => {
    let groupHeader: string;
    if (group.presentationGroupValues === null) {
      groupHeader = "Ungrouped products";
    } else {
      groupHeader = group.presentationGroupValues
        .map((g) => `${g.name}: ${g.value}`)
        .join(" | ");
    }
    return (
      <div className="text-xxs text-deprecated-primary-600">{groupHeader}</div>
    );
  };

  const onRowClickWrapper = (row: LineItemGroupRow | LineItemRow) =>
    row.type === "data" && props.onRowClick
      ? props.onRowClick(row.lineItem)
      : undefined;

  return (
    <DeprecatedTablePanel
      data={rows}
      className="m-12"
      onRowClick={onRowClickWrapper}
      isRowClickable={(row) => {
        if (
          isEmbeddableDashboard ||
          (row.type === "data" &&
            row.lineItem.__typename === "ContractConversionLineItem")
        ) {
          return false;
        }
        return true;
      }}
      getRowTheme={(row) =>
        row.type === "data" || isEmbeddableDashboard ? "enabled" : "highlighted"
      }
      columns={[
        {
          id: "charge",
          header: "Charge",
          render: (row) => {
            if (row.type === "header") {
              // TODO: ideally we would render this as it's own standalone row instead of just within a column
              return getGroupHeader(row);
            }
            const lineItem = row.lineItem;
            if (lineItem.__typename === "ContractUsageLineItem") {
              if ((lineItem.pricing_group_values ?? []).length > 0) {
                return (
                  <div>
                    <div>{lineItem.display_name}</div>
                    <div className="text-xxs text-deprecated-gray-medium">
                      {(lineItem.pricing_group_values ?? [])
                        .map((g) => `${g.name}: ${g.value}`)
                        .join(", ")}
                    </div>
                  </div>
                );
              }
            }

            return `${lineItem.display_name}${
              lineItem.__typename === "ContractSubscriptionLineItem" &&
              lineItem.is_prorated
                ? " (prorated)"
                : ""
            }${
              lineItem.__typename === "ContractUsageLineItem" &&
              lineItem.tier_metadata
                ? ` ${formatTierText(lineItem.tier_metadata)}`
                : ``
            }`;
          },
        },
        {
          id: "commit",
          header: "Applied commit or credit",
          disabled: props.invoiceType !== "ContractUsageInvoice",
          render: (row) =>
            row.type === "header" ? null : (row.lineItem.__typename ===
                "ContractUsageLineItem" ||
                row.lineItem.__typename === "ContractSubscriptionLineItem" ||
                row.lineItem.__typename === "ContractConversionLineItem") &&
              row.lineItem.optionalCommitWithSegment ? (
              <CommitReference
                {...row.lineItem.optionalCommitWithSegment.commit_union}
              />
            ) : (
              "-"
            ),
        },
        {
          id: "start",
          header: "Effective date",
          render: (row) => {
            if (row.type === "header") {
              return null;
            }
            return "inclusive_start_date" in row.lineItem
              ? printDateRange(
                  now,
                  toDayjs(row.lineItem.inclusive_start_date),
                  toDayjs(row.lineItem.exclusive_end_date),
                )
              : renderDate(props.exclusiveEndDate ?? props.issuedAt, {
                  isUtc: true,
                });
          },
        },
        {
          id: "quantity",
          header: "Quantity",
          align: "right",
          render: (row) => {
            if (row.type === "header") {
              return null;
            }
            const lineItem = row.lineItem;
            if (
              (lineItem.__typename === "ContractUsageLineItem" &&
                lineItem.product.__typename === "CompositeProductListItem") ||
              lineItem.__typename === "ContractAWSRoyaltyLineItem" ||
              lineItem.__typename === "ContractGCPRoyaltyLineItem" ||
              lineItem.__typename === "ContractAWSProServiceRoyaltyLineItem" ||
              lineItem.__typename === "ContractGCPProServiceRoyaltyLineItem"
            ) {
              return (
                <RoundedCurrency
                  amount={new Decimal(lineItem.quantity)}
                  creditType={lineItem.credit_type}
                />
              );
            } else if (
              lineItem.__typename === "ContractPostpaidTrueupLineItem"
            ) {
              return "1";
            } else if ("quantity" in lineItem) {
              return <Quantity quantity={lineItem.quantity} rounded />;
            } else {
              return "-";
            }
          },
        },
        {
          id: "unitPrice",
          header: "Unit price",
          align: "right",
          render: (row) => {
            if (row.type === "header") {
              return null;
            }
            const lineItem = row.lineItem;
            if (
              (lineItem.__typename === "ContractUsageLineItem" &&
                lineItem.product.__typename === "CompositeProductListItem") ||
              lineItem.__typename === "ContractAWSRoyaltyLineItem" ||
              lineItem.__typename === "ContractGCPRoyaltyLineItem" ||
              lineItem.__typename === "ContractAWSProServiceRoyaltyLineItem" ||
              lineItem.__typename === "ContractGCPProServiceRoyaltyLineItem"
            ) {
              return `${new Decimal(lineItem.unit_price).mul(100)}%`;
            } else if (
              lineItem.__typename === "ContractPostpaidTrueupLineItem"
            ) {
              return (
                <RoundedCurrency
                  creditType={lineItem.credit_type}
                  amount={new Decimal(lineItem.total)}
                />
              );
            } else if ("unit_price" in lineItem) {
              return (
                <RoundedCurrency
                  creditType={lineItem.credit_type}
                  amount={new Decimal(lineItem.unit_price)}
                />
              );
            }

            return "-";
          },
        },
        {
          id: "total",
          header: "Total",
          align: "right",
          render: (row) =>
            row.type === "header" ? null : (
              <RoundedCurrency
                creditType={row.lineItem.credit_type}
                amount={new Decimal(row.lineItem.total)}
              />
            ),
        },
      ]}
      maxPageSize={50}
    />
  );
};
